<template lang="pug">
v-dialog(v-model="dialogLoading", max-width="290", persistent)
  v-card.pb-4
    v-card-title.flex-column.justify-center
      svg-icon.mt-6(iconClass="loading", className="loading")
      .text-h6.mt-6(style="color: #878787") 載入中...
    v-card-text.text-center
      slot(name="content")
</template>

<script>
export default {
  name: "DialogLoading",
  props: {
    dialogLoading: Boolean,
  },
  methods: {
    closeDialog() {
      this.$emit("closeLoadingDialog");
    },
  },
};
</script>

<style scoped>
svg.loading {
  width: 60px;
  height: 60px;
}

::v-deep .v-dialog {
  width: 100%;
}
</style>

