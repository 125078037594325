<template lang="pug">
.verify
  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-error(
    :title="dialogErrorData.title",
    @closeErrorDialog="closeErrorDialog",
    :dialogError="dialogErrorShow"
  )
    template(#content)
      p(v-html="dialogErrorData.html")
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
</template>

<script>
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogError from "@/components/dialog/DialogError";
import DialogLoading from "@/components/dialog/DialogLoading";
import { verifyEmail } from "@/api/user";

export default {
  name: "Verifiy",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    DialogSuccess,
    DialogError,
    DialogLoading,
  },
  data() {
    return {
      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "驗證成功",
        text: "Email已驗證，請重新登入會員",
      },

      dialogErrorShow: false,
      dialogErrorData: {
        title: "驗證失敗",
        html: "<b>驗證失敗</b><p>請洽詢客服人員</p>",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "正在驗證您的信箱，請稍候",
      },

      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "信件認證 | Game Changer",
        description: "信件認證",
        keywords: "Game changer,籃球,會員,信件認證",
        url: "https://www.gamechanger.tw/verify",
        image: "",
        smoType: "website",
      },
    };
  },
  created() {
    this.showLoadingDialog();
    this.verifyEmail();
  },
  methods: {
    verifyEmail() {
      let inputData = {
        uid: this.$route.query.user,
        token: this.$route.query.token,
      };
      verifyEmail(inputData)
        .then(() => {
          this.closeLoadingDialog();
          this.showSuccessDialog();
          setTimeout(() => {
            this.closeSuccessDialog();
            this.$router.push({ name: "Login" });
          }, 2000);
        })
        .catch(() => {
          this.showErrorDialog();
          setTimeout(() => {
            this.closeErrorDialog();
            this.$router.push({ name: "Home" });
          }, 2000);
        });
    },
    showLoadingDialog() {
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingShow = false;
    },
    showErrorDialog() {
      this.dialogErrorShow = true;
    },
    closeErrorDialog() {
      this.dialogErrorShow = false;
    },
    showSuccessDialog() {
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessShow = false;
    },
  },
};
</script>
