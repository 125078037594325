<template lang="pug">
v-dialog(v-model="dialogSuccess", max-width="290", persistent)
  v-card
    v-card-title.flex-column.justify-center
      .text-h5 {{ title }}
      v-icon.mt-6(x-large, color="#ABC9E3") mdi-checkbox-marked-circle

    v-card-text.text-center.mt-3
      slot(name="content")
    v-card-actions
      v-spacer
      v-btn(color="darken-1", text="", @click="closeDialog") OK
</template>

<script>
export default {
  name: "DialogSuccess",
  props: {
    title: String,
    dialogSuccess: Boolean,
  },
  methods: {
    closeDialog() {
      this.$emit("closeSuccessDialog");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  width: 100%;
}
</style>